import { T } from "@/locales";

export const columns = [
  {
    title: T("序号"),
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: T("入库通知单号"),
    dataIndex: "number",
    width: 180,
    ellipsis: true,
    sorter: true,
    scopedSlots: { customRender: "number" },
  },
  {
    title: T("应收货数量"),
    dataIndex: "total_quantity",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("待收货数量"),
    dataIndex: "remain_quantity",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("状态"),
    dataIndex: "status",
    width: 100,
    ellipsis: true,
    customRender: (value, item, index) => {
      return item.is_completed ? T("已完成") : T("进行中");
    },
  },
  {
    title: T("仓库名称"),
    dataIndex: "warehouse_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("订单类型"),
    dataIndex: "order_type_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("往来单位单号"),
    dataIndex: "external_number",
    width: 180,
    ellipsis: true,
  },
  {
    title: T("货主"),
    dataIndex: "client_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("创建人"),
    dataIndex: "creator_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("创建时间"),
    dataIndex: "create_time",
    sorter: true,
    width: 180,
    ellipsis: true,
  },
  {
    title: T("备注"),
    dataIndex: "remark",
    width: 240,
    ellipsis: true,
  },
  {
    title: T("操作"),
    dataIndex: "action",
    fixed: "right",
    width: 320,
    ellipsis: true,
    scopedSlots: { customRender: "action" },
  },
];
