<template>
  <div>
    <a-row gutter="16">
      <a-col :span="24" :md="8" :xl="5" style="max-width: 256px; margin-bottom: 12px">
        <a-range-picker :value="[searchForm.start_date, searchForm.end_date]" @change="onChangePicker" />
      </a-col>
      <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px">
        <a-select v-model="searchForm.warehouse" :placeholder="$t('仓库')" allowClear style="width: 100%" @change="search">
          <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px">
        <client-select v-model="searchForm.client" :placeholder="$t('货主')" @change="search"></client-select>
      </a-col>
      <a-col :span="24" :md="8" :xl="4" style="max-width: 256px; margin-bottom: 12px">
        <a-select v-model="searchForm.is_completed" :placeholder="$t('状态')" allowClear style="width: 100%" @change="search">
          <a-select-option key="0" :value="false">{{ $t("进行中") }}</a-select-option>
          <a-select-option key="1" :value="true">{{ $t("已完成") }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :span="24" :md="8" :xl="4" style="max-width: 256px; margin-bottom: 12px">
        <a-input v-model="searchForm.search" :placeholder="$t('单据编号, 往来单位单号, 备注')" allowClear @pressEnter="search" />
      </a-col>
      <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
        <a-button type="primary" icon="search" @click="search">{{ $t("查询") }}</a-button>
      </a-col>
    </a-row>

    <a-row>
      <a-col :span="24" style="margin-bottom: 12px">
        <a-button-group>
          <a-popconfirm :title="$t('确定作废吗?')" @confirm="bulkVoid">
            <a-button>{{ $t("作废") }}</a-button>
          </a-popconfirm>
          <a-button @click="exportExcel">{{ $t("导出") }}</a-button>
        </a-button-group>
      </a-col>
    </a-row>

    <div>
      <a-table
        rowKey="id"
        size="small"
        :columns="columns"
        :data-source="items"
        :pagination="pagination"
        :loading="loading"
        :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :scroll="{ x: 1680 }"
        @change="onChangeTable"
      >
        <template slot="number" slot-scope="value, item, index">
          <a @click="detail(item)">{{ value }}</a>
        </template>
        <div slot="action" slot-scope="value, item, index">
          <a-button-group size="small">
            <a-button @click="detail(item)">{{ $t("详情") }}</a-button>
            <a-button v-if="!item.is_completed" type="primary" @click="generalReceipt(item)">{{ $t("收货") }}</a-button>
            <a-button v-if="!item.is_completed" type="primary" @click="palletReceipt(item)">{{ $t("容器收货") }}</a-button>
            <a-button v-if="!item.is_completed" type="primary" @click="quickReceipt(item)">{{ $t("收货入库") }}</a-button>
          </a-button-group>
        </div>
      </a-table>
    </div>

    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />{{ $t("正在导入中, 请等待...") }}</div>
    </a-modal>
  </div>
</template>

<script>
import { exportExcel } from "@/utils/excel";
import { receiptOrdersExport } from "@/api/export";
import { receiptOrderOption, warehouseOption } from "@/api/option";
import { receiptOrderVoid, receiptOrderComplete, receiptOrderBulkComplete, receiptOrderBulkVoid } from "@/api/stockIn";
import { columns } from "./columns";
import { receiptOrderTemplate, receiptOrderImport } from "@/api/import";
import moment from "moment";

export default {
  components: {
    ClientSelect: () => import("@/components/ClientSelect/"),
  },
  data() {
    return {
      columns,
      pagination: { current: 1, total: 0, pageSize: 16, showTotal: (total) => `${this.$t("共")} ${total} ${this.$t("条")}` },
      searchForm: { page: 1, page_size: 16, is_completed: false, is_void: false },
      loading: false,
      items: [],
      warehouseItems: [],
      selectedRowKeys: [],
      selectionRows: [],
      importLoading: false,
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    initData() {
      this.onClearSelected();
      this.searchForm.warehouse = this.currentWarehouse;
      this.searchForm = { ...this.searchForm, ...this.$functions.parseParams(this.$route.query) };
      this.list();
      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
      });
    },
    onChangeTable(pagination, filters, sorter) {
      this.pagination = pagination;
      this.searchForm.page = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onChangePicker(date, dateString) {
      let startDate = date[0],
        endDate = date[1];
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.format("YYYY-MM-DD") : undefined;
      this.search();
    },
    search() {
      this.pagination.current = 1;
      this.searchForm.page = 1;
      this.list();
    },
    list() {
      let searchForm = { ...this.searchForm };
      if (searchForm.end_date) {
        searchForm.end_date = moment(searchForm.end_date).add(1, "days").format("YYYY-MM-DD");
      }

      this.loading = true;
      this.$router.push({ query: this.searchForm });
      receiptOrderOption(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    complete(item) {
      receiptOrderComplete({ id: item.id }).then(() => {
        this.$message.success(this.$t("强制完成成功"));
        this.list();
      });
    },
    voidItem(item) {
      receiptOrderVoid({ id: item.id }).then(() => {
        this.$message.success(this.$t("作废成功"));
        this.list();
      });
    },
    generalReceipt(item) {
      this.$router.push({
        path: "/stock_in/receipt_record_create",
        query: { receipt_order: item.id, warehouse: item.warehouse },
      });
    },
    quickReceipt(item) {
      this.$router.push({
        path: "/stock_in/quick_receipt_record",
        query: { receipt_order: item.id, warehouse: item.warehouse, area: item.area },
      });
    },
    palletReceipt(item) {
      this.$router.push({
        path: "/stock_in/pallet_receipt",
        query: { receipt_order: item.id, warehouse: item.warehouse },
      });
    },
    detail(item) {
      this.$router.push({ path: "/stock_in/receipt_order_detail", query: { id: item.id } });
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    onClearSelected() {
      this.selectedRowKeys = [];
      this.selectionRows = [];
    },
    exportExcel() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning(this.$t("请选择要导出的入库通知单!"));
        return false;
      }
      receiptOrdersExport({ ids: this.selectedRowKeys })
        .then((resp) => {
          exportExcel(resp, this.$t("入库通知单"));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    downloadTemplate() {
      receiptOrderTemplate()
        .then((resp) => {
          exportExcel(resp, this.$t("入库通知单导入模板"));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    importExcel(item) {
      let data = new FormData();
      data.append("file", item.file);
      this.importLoading = true;
      setTimeout(() => {
        receiptOrderImport(data)
          .then(() => {
            this.$message.success(this.$t("导入成功"));
            this.list();
          })
          .catch((err) => {
            alert(this.$t("导入错误:") + "\n" + err.response.data.join("\n"));
          })
          .finally(() => {
            this.importLoading = false;
          });
      }, 1000);
    },
    bulkVoid() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning(this.$t("请选择要作废的入库通知单!"));
        return false;
      }

      receiptOrderBulkVoid({ ids: this.selectedRowKeys }).then(() => {
        this.$message.success(this.$t("作废成功"));
        this.list();
      });
    },
    bulkComplete() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning(this.$t("请选择要强制完成的入库通知单!"));
        return false;
      }

      receiptOrderBulkComplete({ ids: this.selectedRowKeys }).then(() => {
        this.$message.success(this.$t("强制完成成功"));
        this.list();
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
