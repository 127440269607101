import request from "@/utils/request";

// ReceiptOrder
export function receiptOrderList(params) {
  return request({ url: `/receipt_orders/`, method: "get", params });
}

export function receiptOrderRetrieve(params) {
  return request({ url: `/receipt_orders/${params.id}/`, method: "get", params });
}

export function receiptOrderCreate(data) {
  return request({ url: `/receipt_orders/`, method: "post", data });
}

export function receiptOrderBulkDelete(data) {
  return request({ url: `/receipt_orders/bulk_delete/`, method: "post", data });
}

export function receiptOrderMaterials(params) {
  return request({ url: `/receipt_orders/${params.id}/receipt_materials/`, method: "get", params });
}

export function receiptOrderComplete(data) {
  return request({ url: `/receipt_orders/${data.id}/complete/`, method: "post", data });
}

export function receiptOrderVoid(data) {
  return request({ url: `/receipt_orders/${data.id}/void/`, method: "post", data });
}

export function receiptOrderBulkComplete(data) {
  return request({ url: `/receipt_orders/bulk_complete/`, method: "post", data });
}

export function receiptOrderBulkVoid(data) {
  return request({ url: `/receipt_orders/bulk_void/`, method: "post", data });
}

export function receiptOrderPrint(data) {
  return request({ url: `/receipt_orders/print_data/`, method: "post", data });
}

export function quickReceipt(data) {
  return request({ url: `/receipt_orders/${data.id}/quick_receipt/`, method: "post", data });
}

// ReceiptMaterial
export function receiptMaterialList(params) {
  return request({ url: `/receipt_materials/`, method: "get", params });
}

// ReceiptRecord
export function receiptRecordList(params) {
  return request({ url: `/receipt_records/`, method: "get", params });
}

export function receiptRecordRetrieve(params) {
  return request({ url: `/receipt_records/${params.id}/`, method: "get", params });
}

export function receiptRecordCreate(data) {
  return request({ url: `/receipt_records/`, method: "post", data });
}

export function receiptRecordMaterials(params) {
  return request({ url: `/receipt_records/${params.id}/receipt_record_materials/`, method: "get", params });
}

export function receiptRecordVoid(data) {
  return request({ url: `/receipt_records/${data.id}/void/`, method: "post", data });
}

// ReceiptRecordMaterial
export function ReceiptRecordMaterialList(params) {
  return request({ url: `/receipt_record_materials/`, method: "get", params });
}

// CheckRecord
export function checkRecordList(params) {
  return request({ url: `/check_records/`, method: "get", params });
}

export function checkRecordRetrieve(params) {
  return request({ url: `/check_records/${params.id}/`, method: "get", params });
}

export function checkRecordCreate(data) {
  return request({ url: `/check_records/`, method: "post", data });
}

export function checkRecordMaterials(params) {
  return request({ url: `/check_records/${params.id}/check_record_materials/`, method: "get", params });
}

export function checkRecordVoid(data) {
  return request({ url: `/check_records/${data.id}/void/`, method: "post", data });
}

// CheckRecordMaterial
export function checkRecordMaterialList(params) {
  return request({ url: `/check_record_materials/`, method: "get", params });
}

// ShelveOrder
export function shelveOrderList(params) {
  return request({ url: `/shelve_orders/`, method: "get", params });
}

export function shelveOrderMaterials(params) {
  return request({ url: `/shelve_orders/${params.id}/shelve_materials/`, method: "get", params });
}

// ShelveRecord
export function shelveRecordList(params) {
  return request({ url: `/shelve_records/`, method: "get", params });
}

export function shelveRecordMaterials(params) {
  return request({ url: `/shelve_records/${params.id}/shelve_record_materials/`, method: "get", params });
}

export function shelveRecordCreate(data) {
  return request({ url: `/shelve_records/`, method: "post", data });
}

export function shelveRecordVoid(data) {
  return request({ url: `/shelve_records/${data.id}/void/`, method: "post", data });
}

export function receiptFileUpload(data) {
  return request({
    url: "/receipt_files/",
    method: "post",
    data,
  });
}

export function shelveRecordRetrieve(params) {
  return request({ url: `/shelve_records/${params.id}/`, method: "get", params });
}

export function shelveOrderRetrieve(params) {
  return request({ url: `/shelve_orders/${params.id}/`, method: "get", params });
}

export function shelveOrderPrint(data) {
  return request({ url: `/shelve_orders/print_data/`, method: "post", data });
}

export function printReceiptPackingOrder(data) {
  return request({ url: `/receipt_orders/print_packing_order/`, method: "post", data });
}
