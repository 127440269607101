import request from "@/utils/request";

// 仓库
export function warehouseExport(params) {
  return request({
    url: "/warehouses/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 库区
export function reservoirAreasExport(params) {
  return request({
    url: "/reservoir_areas/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 库位
export function locationsExport(params) {
  return request({
    url: "/locations/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 货主
export function clientExport(params) {
  return request({
    url: "/clients/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

export function counterpartyExport(params) {
  return request({
    url: "/counterparties/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 单位
export function unitsExport(params) {
  return request({
    url: "/units/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 订单类型
export function orderTypesExport(params) {
  return request({
    url: "/order_types/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 产品
export function materialsExport(params) {
  return request({
    url: "/materials/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 产品分类
export function categoriesExport(params) {
  return request({
    url: "/categories/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 入库通知单
export function receiptOrdersExport(data) {
  return request({
    url: "/receipt_orders/export/",
    responseType: "blob",
    method: "post",
    data,
  });
}

// 出库通知单
export function deliveryOrdersExport(data) {
  return request({
    url: "/delivery_orders/export/",
    responseType: "blob",
    method: "post",
    data,
  });
}

// 拣货单
export function pickingOrdersExport(data) {
  return request({
    url: "/picking_orders/export/",
    responseType: "blob",
    method: "post",
    data,
  });
}

// 盘点单
export function stockCheckOrdersExport(data) {
  return request({
    url: "/stock_check_orders/export/",
    responseType: "blob",
    method: "post",
    data,
  });
}

// 调拨单
export function stockTransferOrdersExport(data) {
  return request({
    url: "/stock_transfer_orders/export/",
    responseType: "blob",
    method: "post",
    data,
  });
}

// 费用合计
export function chargeSumExport(params) {
  return request({
    url: "/statistics/charge_reports/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 入库其他费用
export function stockInChargeRecordsExport(params) {
  return request({
    url: "/stock_in_charge_records/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 入库卸货费用
export function stockInHandlingRecordsExport(params) {
  return request({
    url: "/stock_in_handling_records/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 入库配送费用
export function stockInTransportRecordsExport(params) {
  return request({
    url: "/stock_in_transport_records/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 出库其他费用
export function stockOutChargeRecordsExport(params) {
  return request({
    url: "/stock_out_charge_records/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 出库配送费用
export function stockOutTransportRecordsExport(params) {
  return request({
    url: "/stock_out_transport_records/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 出库装车费用
export function stockOutHandlingRecordsExport(params) {
  return request({
    url: "/stock_out_handling_records/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 入库记录
export function stockInRecordsExport(params) {
  return request({
    url: "/stock_in_records/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 出库记录
export function stockOutRecordsExport(params) {
  return request({
    url: "/stock_out_records/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 仓储费用
export function settlementStockRecordsExport(params) {
  return request({
    url: "/settlement_stock_records/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 入库单汇总导出
export function receiptOrderSummaryExport(data) {
  return request({
    url: "/receipt_orders/summary_export/",
    responseType: "blob",
    method: "post",
    data,
  });
}

// 出库单汇总导出
export function deliveryOrderSummaryExport(data) {
  return request({
    url: "/delivery_orders/summary_export/",
    responseType: "blob",
    method: "post",
    data,
  });
}

// 调拨单汇总导出
export function stockTransferOrderSummaryExport(data) {
  return request({
    url: "/stock_transfer_orders/summary_export/",
    responseType: "blob",
    method: "post",
    data,
  });
}
